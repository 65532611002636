<template>
	<div class="container" v-show="pageShow">
		<div class="col header">
			<div class="title">个人认证</div>
			<div class="subtitle">所有信息仅用于资格认证，不会用于其他用途，我们将 严格保护您的隐私</div>
		</div>
		<div class="form">
			<van-form validate-first>
				<van-field label="认证身份">
					<template #input>
						<div class="van-cell__value van-field__value" @click="showGroup = true">
							<div class="van-field__body">
								<input type="text" readonly="readonly" placeholder="身份选择" class="van-field__control" :value="groupText" />
								<van-image class="arrow" :src="require('@/assets/arrow-right-gray.png')" fit="cover" />
							</div>
						</div>
					</template>
				</van-field>
				<van-field v-model="formData.username" label="姓名" placeholder="请输入姓名"></van-field>
				<!-- <van-field v-model="formData.id_number" label="身份证号码" placeholder="请输入身份证号" :maxlength="18"></van-field> -->
				<div v-show="formData.group_id == 3">
					<van-field v-model="formData.school_name" label="学校名称" placeholder="请输入学校名称"></van-field>
					<van-field v-model="formData.stu_major" label="就读专业" placeholder="请输入就读专业"></van-field>
				</div>
				<div v-show="formData.group_id == 4">
					<van-field v-model="formData.unit_name" label="所在单位" placeholder="请输入所在单位"></van-field>
					<van-field v-model="formData.department" label="科室专业" placeholder="请输入科室专业"></van-field>
					<van-field label="职称等级">
						<template #input>
							<div class="van-cell__value van-field__value" @click="showTitle = true">
								<div class="van-field__body">
									<input type="text" readonly="readonly" placeholder="请选择职称等级" class="van-field__control" :value="titleText" />
									<van-image class="arrow" :src="require('@/assets/arrow-right-gray.png')" fit="cover" />
								</div>
							</div>
						</template>
					</van-field>
				</div>

				<div class="col idcard-upload">
					<div class="title">上传学生证/校园卡/毕业证照片</div>
					<van-uploader :after-read="afterRead" :max-count="1">
						<div slot="default"><van-image class="img" :src="image ? image : require('@/assets/idcard.png')" fit="cover" /></div>
					</van-uploader>
					<div class="tips">*认证信息仅用于审核，我们将严格保护你的隐私</div>
				</div>
				<div class="btn-view"><van-button block type="default" native-type="submit" @click="submit()">确认提交</van-button></div>
			</van-form>
		</div>
		<van-popup v-model="showGroup" position="bottom" round><van-picker show-toolbar :columns="columns" @confirm="onGroupConfirm" @cancel="showGroup = false" /></van-popup>
		<van-popup v-model="showTitle" position="bottom" round>
			<van-picker show-toolbar :columns="titleList" value-key="name" @confirm="onTitleConfirm" @cancel="showTitle = false" />
		</van-popup>
	</div>
</template>

<script>
import { Uploader } from 'vant';
import { Toast } from 'vant';
export default {
	data() {
		return {
			formData: {
				group_id: '',
				username: '',
				id_number: '',
				image: '',
				school_name: '',
				stu_major: '',
				unit_name: '',
				department: '',
				title_grade_id: ''
			},
			showGroup: false,
			columns: [
				{
					id: 3,
					text: '专业学生'
				},
				{
					id: 4,
					text: '从业人员'
				}
			],
			showTitle: false,
			titleList: [],
			idCardRule: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/,
			pageShow: false
		};
	},
	components: { vanUploader: Uploader },
	computed: {
		image() {
			return this.$store.getters.oss(this.formData.image);
		},
		titleText() {
			let index = this.titleList.findIndex(e => e.id == this.formData.title_grade_id);
			if (index !== -1) {
				return this.titleList[index].name;
			} else {
				return '';
			}
		},
		groupText() {
			let index = this.columns.findIndex(e => e.id == this.formData.group_id);
			if (index !== -1) {
				return this.columns[index].text;
			} else {
				return '';
			}
		}
	},
	async created() {
		Toast.loading({
			message: '',
			forbidClick: true,
			loadingType: 'spinner'
		});
		let authRes = await this.$service.post('auth/authDetail');
		if (authRes.data && authRes.data.group_id) this.formData = authRes.data;
		let titleRes = await this.$service.post('auth/titleList');
		this.titleList = titleRes.data;
		Toast.clear();
		this.pageShow = true;
	},
	methods: {
		async submit() {
			if (this.formData.group_id == '') {
				this.$toast('请选择认证身份');
			} else if (this.formData.username == '') {
				this.$toast('请输入姓名');
			}
			// else if (this.formData.id_number == '') {
			// 	this.$toast('请输入身份证号');
			// }
			else if (!this.idCardRule.test(this.formData.id_number)) {
				this.$toast('请输入正确的身份证号');
			} else if (this.formData.group_id == 3 && this.formData.school_name == '') {
				this.$toast('请输入学校名称');
			} else if (this.formData.group_id == 3 && this.formData.stu_major == '') {
				this.$toast('请输入就读专业');
			} else if (this.formData.group_id == 4 && this.formData.unit_name == '') {
				this.$toast('请输入所在单位');
			} else if (this.formData.group_id == 4 && this.formData.department == '') {
				this.$toast('请输入所在科室');
			} else if (this.formData.group_id == 4 && this.formData.title_grade_id == '') {
				this.$toast('请选择职称等级');
			} else if (this.formData.image == '') {
				this.$toast('请上传证明材料');
			} else {
				Toast.loading({
					message: '',
					forbidClick: true,
					loadingType: 'spinner'
				});
				await this.$service.post('auth/authentication', this.formData);
				Toast.clear();
				Toast.success({
					message: '认证已提交',
					duration: 800
				});
				this.$app.$emit('authSuccess');
				setTimeout(() => {
					this.$router.go(-1);
				}, 800);
			}
		},
		onTitleConfirm(e) {
			this.formData.title_grade_id = e.id;
			this.showTitle = false;
		},
		onGroupConfirm(e) {
			this.formData.group_id = e.id;
			this.showGroup = false;
		},
		afterRead(file) {
			file.status = 'uploading';
			file.message = '上传中...';
			this.$service.upload(file.file).then(res => {
				file.status = 'done';
				file.message = '上传成功';
				this.formData.image = res.data.url;
			});
		}
	}
};
</script>

<style scoped lang="less">
@import '~@/styles/form.less';
@import './auth.less';
</style>
